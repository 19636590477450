var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditAssetDimensionStyled', [_vm.assetDimension ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.assetDimension,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": _vm.isDirty,
      "canCancel": _vm.isDirty
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_vm.assetDimension ? _c('AssetDimensionStyled', [_c('div', [_c('AutoForm', {
    attrs: {
      "model": _vm.assetDimension,
      "modelName": 'AssetDimension',
      "filterFields": _vm.filterFields,
      "disabledFields": _vm.disabledFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.assetDimension = $event;
      }
    }
  }), _c('div', {
    staticClass: "heatmap"
  }, [_vm._v(" Heatmap "), _c('span', [_vm._v(" " + _vm._s(_vm.heatmap) + " ")])]), _c('div', {
    staticClass: "type"
  }, [_c('span', [_vm._v(" Increasing trend color ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.assetDimensionTrendColors,
      "value": _vm.trendColor,
      "open-direction": "above"
    },
    on: {
      "select": _vm.setIncreasingTrendColor
    }
  })], 1)], 1), !_vm.isHeatmapDimension ? _c('ModelAssociator', {
    attrs: {
      "title": 'Compatible Widgets',
      "models": _vm.compatibleWidgets,
      "sourceModelName": 'assetDimension',
      "targetModelName": 'widgetType',
      "targetModelNamePlural": 'widgetTypes',
      "whereSearchInputType": 'WidgetTypeWhereInput',
      "whereSearchVariable": ['name_contains']
    },
    on: {
      "connect": _vm.addCompatibleWidgetType,
      "disconnect": _vm.deleteCompatibleWidgetType
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }