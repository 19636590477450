<template>
  <EditAssetDimensionStyled>
    <ModelEditor
      v-if="assetDimension"
      :title="title"
      :model="assetDimension"
      :errors="errors"
      :loading="$apollo.loading"
      :canSave="isDirty"
      :canCancel="isDirty"
      @save="save"
      @cancel="restore"
    >
      <AssetDimensionStyled v-if="assetDimension">
        <div>
          <AutoForm
            :model.sync="assetDimension"
            :modelName="'AssetDimension'"
            :filterFields="filterFields"
            :disabledFields="disabledFields"
          />
          <div class="heatmap">
            Heatmap <span> {{ heatmap }} </span>
          </div>
          <div class="type">
            <span> Increasing trend color </span>
            <Multiselect
              :options="assetDimensionTrendColors"
              :value="trendColor"
              open-direction="above"
              @select="setIncreasingTrendColor"
            />
          </div>
        </div>
        <ModelAssociator
          :title="'Compatible Widgets'"
          :models="compatibleWidgets"
          :sourceModelName="'assetDimension'"
          :targetModelName="'widgetType'"
          :targetModelNamePlural="'widgetTypes'"
          :whereSearchInputType="'WidgetTypeWhereInput'"
          :whereSearchVariable="['name_contains']"
          @connect="addCompatibleWidgetType"
          @disconnect="deleteCompatibleWidgetType"
          v-if="!isHeatmapDimension"
        />
      </AssetDimensionStyled>
    </ModelEditor>
  </EditAssetDimensionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import isEqual from 'lodash/isEqual'
import { AutoForm, ModelAssociator } from '@common/components'
import ModelEditor from '@/components/misc/ModelEditor'
import Multiselect from 'vue-multiselect'
import chroma from 'chroma-js'
import { FlashMessages } from '@common/singletons'
import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimension.gql'
import ASSET_DIMENSION_TREND_COLOR_QUERY from '#/graphql/assetDimensions/trendColor.gql'
import UPDATE_ASSET_DIMENSION_MUTATION from '#/graphql/assetDimensions/updateAssetDimension.gql'

const AssetDimensionStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;

  .type {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    > span {
      margin-left: 0.5rem;
    }
    .multiselect {
      max-width: 20rem;
      margin: 1rem;
      margin-left: 1.25rem;
    }
  }

  .heatmap {
    display: flex;
    align-items: center;
    margin: 0.5rem 7.4rem;
    grid-gap: 1.5rem;
    span {
      color: ${p => p.theme.colors.black};
      border-radius: 5px;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(1).css()};
      padding: 0.3rem;
    }
  }
`

const EditAssetDimensionStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`
export default {
  components: {
    AssetDimensionStyled,
    ModelEditor,
    ModelAssociator,
    AutoForm,
    EditAssetDimensionStyled,
    Multiselect,
  },
  data() {
    return {
      assetDimension: null,
      assetDimensionLocal: null,
      errors: [],
      disabledFields: ['name', 'createdAt', 'updatedAt'],
      filterFields: ['usedInDashboardCount', 'hasResolver', 'compatibleWidgets', 'isPlottable', 'hasHeatmap'],
      assetDimensionTrendColors: [],
    }
  },
  computed: {
    title() {
      return `AssetDimension ${this.assetDimension?.name ?? ''}`
    },
    compatibleWidgets() {
      if (!Array.isArray(this.assetDimension?.compatibleWidgets)) {
        return []
      }
      return this.assetDimension?.compatibleWidgets.map(widget => ({
        ...widget,
        label: widget.name,
      }))
    },
    isDirty() {
      return !isEqual(this.assetDimension, this.assetDimensionLocal)
    },
    trendColor() {
      return this.assetDimension?.increasingTrendColor ?? ''
    },
    heatmap() {
      const heatmapFlag = this.assetDimension?.hasHeatmap ?? false ? 'Yes' : 'No'
      return heatmapFlag
    },
    isHeatmapDimension() {
      return this.assetDimension?.hasHeatmap ?? false
    },
  },
  methods: {
    async save() {
      const data = { ...this.assetDimension }
      delete data.id
      delete data.__typename
      this.filterFields.forEach(key => {
        delete data[key]
      })
      this.disabledFields.forEach(key => {
        delete data[key]
      })

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ASSET_DIMENSION_MUTATION,
          variables: {
            where: {
              id: this.$route.params.id,
            },
            data,
          },
        })

        this.assetDimensionLocal = res?.data.updateAssetDimension ?? null
        FlashMessages.$emit('success', `AssetDimension ${this.assetDimensionLocal.name} saved successfully`, {
          timeout: 3000,
        })
        this.assetDimensionLocal = { ...this.assetDimension, ...this.assetDimensionLocal }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.assetDimension = this.assetDimensionLocal
    },
    async addOrDeleteEntity(id, mode, entity) {
      const assetDimensionID = this.$route.params.id
      if (!assetDimensionID || !entity || !mode) {
        return
      }
      await this.$apollo.mutate({
        mutation: UPDATE_ASSET_DIMENSION_MUTATION,
        variables: {
          where: {
            id: assetDimensionID,
          },
          data: {
            [entity]: {
              [mode]: {
                id,
              },
            },
          },
        },
      })
    },
    async addCompatibleWidgetType({ id }) {
      return this.addOrDeleteEntity(id, 'connect', 'compatibleWidgets')
    },
    async deleteCompatibleWidgetType({ id }) {
      return this.addOrDeleteEntity(id, 'disconnect', 'compatibleWidgets')
    },
    setIncreasingTrendColor(color) {
      this.assetDimension.increasingTrendColor = color
      this.save()
    },
  },
  apollo: {
    assetDimension: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.assetDimension = data?.assetDimension
        this.assetDimensionLocal = data?.assetDimension
      },
    },
    assetDimensionTrendColors: {
      query: ASSET_DIMENSION_TREND_COLOR_QUERY,
      update({ assetDimensionTrendColors }) {
        if (!Array.isArray(assetDimensionTrendColors?.enumValues)) {
          return []
        }
        return assetDimensionTrendColors?.enumValues.map(t => t.name)
      },
    },
  },
}
</script>
